import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Article",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiTabs",
      "ComponentUiText",
      "Contact",
      "ContactUsForm",
      "GetStarted",
      "GetStartedForm",
      "Home",
      "Layout",
      "ReactIconsIconlibrary",
      "SitemapSitemap",
      "SitemapSitemapCache",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Article",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiTabs",
      "ComponentUiText",
      "Contact",
      "ContactUsForm",
      "GetStarted",
      "GetStartedForm",
      "Home",
      "Layout",
      "ReactIconsIconlibrary",
      "SitemapSitemap",
      "SitemapSitemapCache",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const ArticleFragmentDoc = gql`
    fragment Article on ArticleEntity {
  id
  attributes {
    pathname
  }
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
  image {
    data {
      attributes {
        url
      }
    }
  }
}
    `;
export const GridFragmentDoc = gql`
    fragment Grid on ComponentUiGrid {
  id
  visible
  title
  children {
    ...Entry
  }
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
  title_rich
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  visible
  button {
    id
    url
    icon
    target
    title
  }
  heading {
    ...Card
  }
}
    `;
export const PointsFragmentDoc = gql`
    fragment Points on ComponentUiTabs {
  id
  point
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  description
  key
  label
  subtitle
  tab_image {
    data {
      attributes {
        url
        hash
        mime
        provider
        name
        size
      }
    }
  }
  points {
    ...Points
  }
}
    `;
export const ContactUsFormFragmentDoc = gql`
    fragment ContactUsForm on ContactUsForm {
  email
  message
  subject
}
    `;
export const GetStartedFormFragmentDoc = gql`
    fragment GetStartedForm on GetStartedForm {
  first_name
  last_name
  company_name
  email
}
    `;
export const CreateContactUsMessageDocument = gql`
    mutation createContactUsMessage($input: ContactUsFormInput!) {
  createContactUsForm(data: $input) {
    data {
      id
      attributes {
        ...ContactUsForm
      }
    }
  }
}
    ${ContactUsFormFragmentDoc}`;
export type CreateContactUsMessageMutationFn = Apollo.MutationFunction<CreateContactUsMessageMutation, CreateContactUsMessageMutationVariables>;
export function useCreateContactUsMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactUsMessageMutation, CreateContactUsMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactUsMessageMutation, CreateContactUsMessageMutationVariables>(CreateContactUsMessageDocument, options);
      }
export type CreateContactUsMessageMutationHookResult = ReturnType<typeof useCreateContactUsMessageMutation>;
export type CreateContactUsMessageMutationResult = Apollo.MutationResult<CreateContactUsMessageMutation>;
export const CreateGetStartedMessageDocument = gql`
    mutation createGetStartedMessage($input: GetStartedFormInput!) {
  createGetStartedForm(data: $input) {
    data {
      id
      attributes {
        ...GetStartedForm
      }
    }
  }
}
    ${GetStartedFormFragmentDoc}`;
export type CreateGetStartedMessageMutationFn = Apollo.MutationFunction<CreateGetStartedMessageMutation, CreateGetStartedMessageMutationVariables>;
export function useCreateGetStartedMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateGetStartedMessageMutation, CreateGetStartedMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGetStartedMessageMutation, CreateGetStartedMessageMutationVariables>(CreateGetStartedMessageDocument, options);
      }
export type CreateGetStartedMessageMutationHookResult = ReturnType<typeof useCreateGetStartedMessageMutation>;
export type CreateGetStartedMessageMutationResult = Apollo.MutationResult<CreateGetStartedMessageMutation>;
export const ArticleDocument = gql`
    query article($key: StringFilterInput!) {
  articles(filters: {pathname: $key}) {
    data {
      ...Article
    }
  }
}
    ${ArticleFragmentDoc}`;
export function useArticleQuery(baseOptions: Apollo.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
      }
export function useArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
        }
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleQueryResult = Apollo.QueryResult<ArticleQuery, ArticleQueryVariables>;
export const ArticlesDocument = gql`
    query articles {
  articles {
    data {
      ...Article
    }
  }
}
    ${ArticleFragmentDoc}`;
export function useArticlesQuery(baseOptions?: Apollo.QueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
      }
export function useArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
        }
export type ArticlesQueryHookResult = ReturnType<typeof useArticlesQuery>;
export type ArticlesLazyQueryHookResult = ReturnType<typeof useArticlesLazyQuery>;
export type ArticlesQueryResult = Apollo.QueryResult<ArticlesQuery, ArticlesQueryVariables>;
export const ContactUsDocument = gql`
    query contactUs {
  contact {
    data {
      attributes {
        contact_icons {
          data {
            attributes {
              url
            }
          }
        }
        email
        phone
        header {
          subtitle
          title
        }
      }
    }
  }
}
    `;
export function useContactUsQuery(baseOptions?: Apollo.QueryHookOptions<ContactUsQuery, ContactUsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactUsQuery, ContactUsQueryVariables>(ContactUsDocument, options);
      }
export function useContactUsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactUsQuery, ContactUsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactUsQuery, ContactUsQueryVariables>(ContactUsDocument, options);
        }
export type ContactUsQueryHookResult = ReturnType<typeof useContactUsQuery>;
export type ContactUsLazyQueryHookResult = ReturnType<typeof useContactUsLazyQuery>;
export type ContactUsQueryResult = Apollo.QueryResult<ContactUsQuery, ContactUsQueryVariables>;
export const FooterCardDocument = gql`
    query footerCard {
  home {
    data {
      attributes {
        card {
          ...Section
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}`;
export function useFooterCardQuery(baseOptions?: Apollo.QueryHookOptions<FooterCardQuery, FooterCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FooterCardQuery, FooterCardQueryVariables>(FooterCardDocument, options);
      }
export function useFooterCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FooterCardQuery, FooterCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FooterCardQuery, FooterCardQueryVariables>(FooterCardDocument, options);
        }
export type FooterCardQueryHookResult = ReturnType<typeof useFooterCardQuery>;
export type FooterCardLazyQueryHookResult = ReturnType<typeof useFooterCardLazyQuery>;
export type FooterCardQueryResult = Apollo.QueryResult<FooterCardQuery, FooterCardQueryVariables>;
export const GetStartedDocument = gql`
    query getStarted {
  getStarted {
    data {
      attributes {
        infoSection {
          ...Card
        }
        bg_image {
          data {
            ...File
          }
        }
      }
    }
  }
}
    ${CardFragmentDoc}
${FileFragmentDoc}`;
export function useGetStartedQuery(baseOptions?: Apollo.QueryHookOptions<GetStartedQuery, GetStartedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStartedQuery, GetStartedQueryVariables>(GetStartedDocument, options);
      }
export function useGetStartedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStartedQuery, GetStartedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStartedQuery, GetStartedQueryVariables>(GetStartedDocument, options);
        }
export type GetStartedQueryHookResult = ReturnType<typeof useGetStartedQuery>;
export type GetStartedLazyQueryHookResult = ReturnType<typeof useGetStartedLazyQuery>;
export type GetStartedQueryResult = Apollo.QueryResult<GetStartedQuery, GetStartedQueryVariables>;
export const HeroDocument = gql`
    query hero {
  home {
    data {
      attributes {
        hero {
          ...Section
        }
        companies {
          ...Grid
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}
${GridFragmentDoc}
${EntryFragmentDoc}`;
export function useHeroQuery(baseOptions?: Apollo.QueryHookOptions<HeroQuery, HeroQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeroQuery, HeroQueryVariables>(HeroDocument, options);
      }
export function useHeroLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeroQuery, HeroQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeroQuery, HeroQueryVariables>(HeroDocument, options);
        }
export type HeroQueryHookResult = ReturnType<typeof useHeroQuery>;
export type HeroLazyQueryHookResult = ReturnType<typeof useHeroLazyQuery>;
export type HeroQueryResult = Apollo.QueryResult<HeroQuery, HeroQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        hero {
          ...Section
        }
        companies {
          ...Grid
        }
        product {
          ...Section
        }
        primaryLeft {
          ...Section
        }
        primaryRight {
          ...Section
        }
        singleTitle {
          ...Card
        }
        titleWithImage {
          ...Card
        }
        primarySingle {
          ...Section
        }
        versionControl {
          ...Card
        }
        safetyGrid {
          ...Grid
        }
        pricing {
          ...Grid
        }
        card {
          ...Section
        }
        pricing_buttons {
          ...Link
        }
        features_title
        feature_tabs {
          ...Tab
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}
${GridFragmentDoc}
${EntryFragmentDoc}
${LinkFragmentDoc}
${TabFragmentDoc}
${PointsFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const LayoutDocument = gql`
    query layout {
  layout {
    data {
      attributes {
        menu {
          ...Link
        }
      }
    }
  }
}
    ${LinkFragmentDoc}`;
export function useLayoutQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
      }
export function useLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
        }
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>;
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>;
export type LayoutQueryResult = Apollo.QueryResult<LayoutQuery, LayoutQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;