import { FC, lazy } from "react"
import { Outlet, RouteObject } from "react-router-dom"

import DefaultLayout, { Loader } from "../components/layout"

const Home = lazy<FC>(() => import("./home"))
const Article = lazy<FC>(() => import("./article"))
const BlogList = lazy<FC>(() => import("./blog-list"))
const ContactUs = lazy<FC>(() => import("./contact"))
const GetStarted = lazy<FC>(() => import("./getStarted"))
const Hiring = lazy<FC>(() => import("./hiring"))
const Team = lazy<FC>(() => import("./team"))
const Services = lazy<FC>(() => import("./services"))
const PrivacyAndPolicy = lazy<FC>(() => import("./privacy-and-policy"))

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
      },
      {
        Component: BlogList,
        path: "/blog",
      },
      {
        Component: Article,
        path: "/blog/:id",
      },
      {
        Component: Team,
        path: "/team",
      },
      {
        Component: ContactUs,
        path: "/contact",
      },
      {
        Component: GetStarted,
        path: "/get-started",
      },
      {
        Component: Hiring,
        path: "/hiring",
      },
      {
        Component: Services,
        path: "/services",
      },
      {
        Component: PrivacyAndPolicy,
        path: "/privacy-and-policy",
      },
      {
        Component: Outlet,
        path: "*",
      },
    ],
  },
]

export default routes
