import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorActiveBarHeight: 0,
      colorPrimaryBg: "unset",
    },
    Layout: {
      colorBgLayout: "unset",
      colorBgHeader: "unset",
      colorBgBody: "unset",
    },
  },
  token: {
    fontSize: 16,
    fontFamily: "Inter, sans-serif",
    colorText: "#0E0D21",
    colorTextSecondary: "#69696F",
    colorPrimary: "#4D45BB",
    paddingContentVertical: 20,
    paddingContentVerticalLG: 16,
    paddingContentHorizontal: 80,
    paddingContentHorizontalLG: 10,
    controlHeight: 32,
    fontSizeHeading1: 64,
    lineHeightHeading1: 1.43,
    fontSizeHeading2: 47,
    lineHeightHeading2: 1.29,
    fontSizeHeading3: 32,
    fontSizeHeading4: 24,
    lineHeightHeading4: 1.4,
  },
}

export default theme
