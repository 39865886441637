import { Col, Row, Typography, Button } from "antd"
import { FC } from "react"
import Container from "../container/Container"
import { Logo } from "../logo"
import { DesktopMenu } from "../menu/DesktopMenu"
import { ReactComponent as GithubImage } from "../../icons/github.svg"
import { ReactComponent as TwitterImage } from "../../icons/twitter.svg"
import { ReactComponent as YoutubeImage } from "../../icons/youtube.svg"
import { ReactComponent as RedditImage } from "../../icons/reddit.svg"
import { ReactComponent as MediumImage } from "../../icons/medium.svg"
import { ReactComponent as LinkedinImage } from "../../icons/linkedin.svg"
import './Footer.less'

const Footer: FC = () => {
  return (
    <Container>
      <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>
          <Logo />
          <Typography.Paragraph className={"footer-text"}>The Enterprise-grade Generative AI Application Development Platform for Every Business.</Typography.Paragraph>
          <Button className={"footer-social-link"} type={"link"} href={"https://github.com/YiVal/YiVal"} target={"_blank"}>
            <GithubImage/>
          </Button>
          <Button className={"footer-social-link"} type={"link"} href={"https://twitter.com/yivalloveaigc"} target={"_blank"}>
            <TwitterImage/>
          </Button>
          <Button className={"footer-social-link"} type={"link"} href={"https://www.youtube.com/@YiVal"} target={"_blank"}>
            <YoutubeImage/>
          </Button>
          <Button className={"footer-social-link"} type={"link"} href={"https://www.reddit.com/user/YiVal/"} target={"_blank"}>
            <RedditImage/>
          </Button>
          <Button className={"footer-social-link"} type={"link"} href={"https://medium.com/@yivalloveaigc"} target={"_blank"}>
            <MediumImage/>
          </Button>
          <Button className={"footer-social-link"} type={"link"} href={"https://www.linkedin.com/company/yival/"} target={"_blank"}>
            <LinkedinImage/>
          </Button>

        </Col>
        <Col span={12} className={'page-footer-wrapper'}>
          <DesktopMenu header={ false } />
        </Col>
        <Col span={24} className={"footer-copyright-wrapper"}>
          <Typography.Paragraph className={"footer-copyright"}>© Copyright 2023 YiVal, Inc. All rights reserved.</Typography.Paragraph>
        </Col>
      </Row>
    </Container>
  )
}

export { Footer }
