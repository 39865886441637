import { Col, Row } from "antd"
import { FC } from "react"
import './Header.less'
import { Logo } from "../logo"
import { DesktopMenu } from "../menu/DesktopMenu"
import { HeaderControls } from "../headerControls/HeaderControls"
import Container from "../container/Container"
import { MobileMenu } from "../menu/MobileMenu"

const Header: FC = () => {
  return (
    <Container>
      <Row className={'header-row'} align={"middle"} justify={"space-between"}>
        <Col>
          <Logo />
        </Col>
        <Col className={'header-navigation-menu-desc'}>
          <DesktopMenu header={ true } />
        </Col>
        <Col className={'header-controls-wrapper'}>
          <HeaderControls />
        </Col>
        <Col className={'header-navigation-menu-mobi'}>
          <MobileMenu />
        </Col>
      </Row>
    </Container>
  )
}

export { Header }
