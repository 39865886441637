import { Button, Col, Row } from "antd"
import { FC } from "react"
import "./index.less"

const HeaderControls: FC = () => {
  return (
    <Row align={"middle"} justify={"center"} gutter={[21, 0]} className={"header-controls"}>
      <Col>
        {/*<Button type={"text"} className={"header-button-sign"}>*/}
        {/*  Sign in*/}
        {/*</Button>*/}
      </Col>
      <Col>
        <Button
          type={"primary"}
          href={"https://calendly.com/yival/30min"}
          target={"_blank"}
          className={"header-button-demo"}
        >
          Book Demo
        </Button>
      </Col>
    </Row>
  )
}

export { HeaderControls }
