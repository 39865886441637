import './Loader.less'

import { Spin, SpinProps } from "antd"
import { FC } from "react"

const Loader: FC<SpinProps> = ({ spinning }) =>
    <Spin
        size={"large"}
        className={spinning ? "page-loader page-loader-show" : "page-loader page-loader-hide"}
    />

export { Loader }