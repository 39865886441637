import { Layout as BaseLayout } from "antd"
import { FC, Suspense, useState, useEffect } from "react"
import { Outlet } from "react-router-dom"
import { Footer, Header } from "."
import './Header.less'
import './Footer.less'

const Layout: FC = () => { 
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1);
    });
  }, []);

  return (
    <BaseLayout>
      <BaseLayout.Header className={"page-header"}>
        <Header />
      </BaseLayout.Header>
      <BaseLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </BaseLayout>
      <BaseLayout.Footer
        className={"page-footer"}
        style={{
              opacity: scroll ? "1" : "0",
          }}
      >
        <Footer />
      </BaseLayout.Footer>
    </BaseLayout>
  )
}

export { Layout }
