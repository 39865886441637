const menuItems = [
  {
    name: "Blog",
    path: "/blog",
    target: "self",
  },
  {
    name: "Team",
    path: "/team",
    target: "self",
  },
  {
    name: "Docs",
    path: "https://yival.github.io/YiValApi/",
    target: "blank",
  },
  {
    name: "Contact",
    path: "/contact",
    target: "self",
  },
  {
    name: "Services",
    path: "/services",
    target: "self",
  },
  {
    name: "PrivacyAndPolicy",
    path: "/privacy-and-policy",
    target: "self",
  },
]

export { menuItems }
