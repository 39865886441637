import Navigation from "./Navigation"
import { FC } from "react"
import "./DesktopMenu.less"
import { menuItems } from "./data/menuItems"
import { useLayoutQuery } from "../../graphql"

type MenuPlace = {
  header: boolean,
  closeDrawer?: () => void;
}

const DesktopMenu: FC<MenuPlace> = ({header, closeDrawer}) => {
  const { data } = useLayoutQuery()
  const links = data?.layout?.data?.attributes?.menu

  return <ul className={header ? "header-menu" : "footer-menu"}>
    {
      links?.[0]
        ? links?.map((item, index) =>
          <li key={index}>
            <Navigation onClick={() => closeDrawer && closeDrawer()} title={item?.title} link={item?.url} target={`_${item?.target}`} />
          </li>)
        : menuItems.map((item, index) =>
          <li key={index}>
            <Navigation title={item.name} link={item.path} target={`_${item?.target}`} />
          </li>)
    }
  </ul>
}

export { DesktopMenu }