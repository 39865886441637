import { Space, Typography } from "antd"
import { FC } from "react"
import './index.less'
import { ReactComponent as LogoImage } from "../../icons/logo.svg"

const Logo: FC = () => {
  return (
    <Space direction={"horizontal"} align={"center"} className={"logo-wrapper"} onClick={() => window.location.assign("/")}>
      <LogoImage className={"logo-image"}/>
      <Typography.Paragraph className={"logo-text"}>YIVAL</Typography.Paragraph>
    </Space>
  )
}

export { Logo }
