import { BurgerMenuIcon } from "../../icons/BurgerMenu.icon"
import { Button, Drawer } from "antd"
import React, { useState } from "react"
import './MobileMenu.less'
import { DesktopMenu } from "./DesktopMenu"


export const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const showDrawer = () => {
    setIsOpen(prev => !prev);
  }

  const closeDrawer = () => {
    setIsOpen(prev => !prev);
  }

  return (
    <React.Fragment>
        <Button style={{backgroundColor: 'inherit', border: 'none', boxShadow: 'none'}} type={"primary"} onClick={showDrawer} icon={<BurgerMenuIcon />} />
        <Drawer
          onClose={closeDrawer}
          open={isOpen}
          styles={{
            body: {
              paddingBottom: 80,
            },
          }}
        >
          <DesktopMenu closeDrawer={closeDrawer} header={false} />
        </Drawer>
    </React.Fragment>

  )
}